import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import { useStore } from 'effector-react';
import { $theme } from 'models/staticData';
import React from 'react';
import { createContext } from 'react';

const ThemeContext = createContext(null);

interface ThemeProviderProps {
  children?: React.ReactNode,
}

const ThemeProvider = (props: ThemeProviderProps) => {
  const strapiTheme = useStore($theme);

  const theme = createTheme({
    fonts: {
      calibri: {
        main: 'Calibri',
        bold: 'Calibri-bold',
        italic: 'Calibri-italic',
      },
      openSans: {
        main: 'open_sansregular',
        semiBold: 'open_sanssemibold',
      },
      fontAwesome: 'fontAwesome',
      constantia: 'Constantia',
    },
    colors: {
      chambray: '#4e5e75',
      tundora: '#454444',
      actual: '#739953',
      notActual: '#f2d389',
    },
    palette: {
      primary: {
        main: strapiTheme.data.attributes.color,
      }
    }
  });

  return (
    <ThemeContext.Provider value={null}>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        {props.children}
      </MUIThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };