import * as Sentry from '@sentry/react';
import axios, { AxiosError,AxiosResponse } from 'axios';
import parse from 'date-fns/parse';
import {guard, sample} from 'effector';
import { defaultLang } from 'locales';
import { Cookies } from 'react-cookie';

import {
  $baseSchedule,
  $filter,
  $isLoading,
  changeFilter,
  fetchSchedule,
  fetchWeekScheduleFx,
  resetFilter,
  resetFilterFx,
} from '.';
import { ScheduleResponse, WeekSchedule, WeekScheduleRespone } from './types';

const apiInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

apiInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    Sentry.captureException(error);
    return Promise.reject(error);
  },
);

fetchWeekScheduleFx.use(async ({lang=defaultLang, parishId={id: ''}, massLang={id: ''}, online=false, rorate=false,}) => {
  const {
    NEXT_PUBLIC_API_URL
  } = process.env;
  const weekSchedule: WeekSchedule = {
    startWeekDate: new Date(),
    schedule: [],
    nav: {
      selected: {
        city: {
          type: '',
          value: '',
          name: '',
        },
        online: {
          type: '',
          value: '',
          name: '',
        },
      },
      guided: {
        parish: [],
        city: [],
        lang: [],
        online: [],
        rorate: [],
      },
    },
  };;

  const res: AxiosResponse = await apiInstance.get(`${NEXT_PUBLIC_API_URL}/api/mass/week?lang=${lang}&parishId=${parishId ? parishId.id : ''}&massLang=${massLang ? massLang.id : ''}&online=${online}&rorate=${rorate}`);
  const weekScheduleResponse: WeekScheduleRespone = { ...res.data };

  weekSchedule.startWeekDate = parse(weekScheduleResponse.startWeekDate, 'MM/dd/yyyy', new Date());
  weekSchedule.schedule = weekScheduleResponse.schedule?.map((i: ScheduleResponse) => ({ ...i, date: parse(i.date, 'MM/dd/yyyy', new Date()) }));
  weekSchedule.nav = weekScheduleResponse.nav;

  return weekSchedule;
});

resetFilterFx.use(() => {
  const cookies = new Cookies();
  cookies.remove('cityId');
});

sample({
  clock: resetFilter,
  target: resetFilterFx
});

$baseSchedule.on(
  fetchWeekScheduleFx.doneData, (_, state) => state
);

guard({
  clock: fetchWeekScheduleFx.doneData,
  filter: (state) => Object.keys(state.nav.guided).length === 0,
  target: resetFilter,
});

$isLoading
  .on(fetchWeekScheduleFx, () => true)
  .on(fetchWeekScheduleFx.done, () => false)
  .on(fetchWeekScheduleFx.fail, () => false);

$filter
  .on(changeFilter, (prevFilter, filter) => ({...prevFilter, ...filter}))
  .reset(resetFilter);

sample({
  clock: fetchSchedule,
  source: $filter,
  fn: (filters, lang) => ({...filters, lang}),
  target: fetchWeekScheduleFx,
});
