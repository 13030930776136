const translationBE = () => ({
  title: 'Святая Імша',
  description: 'Святая Эўхарыстыя ў горадзе Мінск: расклад, час і месца. Рыма-Каталіцкая Імша і Грэка-Каталіцкая Літургія',
  filterName: 'Фільтр Імшаў',
  radioTitle: 'Слухай Святую Імшу',
  radioSubTitle: 'трансляцыя Радыё Марыя ў Беларусі',
  toggleBtnTitle: (count: number) => (count === 1) ? 'Святая Імша ў 1 парафіі' : `Святая Імша ў ${count} парафіях`,
  messLangLabel: 'Мова',
  messOnline: 'Імша online',
  messActual: 'Iнфармацыя актуальная',
  messActualInfo: 'Апошняе абнаўленне раскладу Імшаў гэтай парафіі было меньш чым 7 дзён таму',
  messNotActualInfo: 'Апошняе абнаўленне раскладу Імшаў гэтай парафіі было больш чым 7 дзён таму',
  messNotActual: 'Інфармацыю трэба ўдакладніць',
  messNotActualLastUpdate: 'Апошняе абнаўленне',
  why: 'Чаму?',
  noOptionsText: 'не знойдзена',
  filterPlaceholder: 'Пошук',
  filterLangLabel: 'Мова Імшы',
  filterParishLabel: 'Парафія',
  filterCityLabel: 'Населены пункт',
  filterAttendance: {
    title: 'Паказваць толькі Імшы анлайн',
    subtitle: 'відэа трансляцыя',
  },
  filterRorate: {
    title: 'Паказваць толькі раратнія Імшы',
  },
  footer: {
    about: 'Аб праекце',
    info: {
      leftColumn: 'Дзе і а якой гадзіне можна сустрэцца з Хрыстом у сталіцы Беларусі? Як знайсці актуальны расклад набажэнстваў у Мінску? Дзе і ў колькі будзе каталіцкая Імша ў касцёле ці Імша онлайн?',
      rightColumn: 'Інфармацыя пра месца і час цэлебрацыі святой Эўхарыстыі ў горадзе Мінску прадстаўлена на нашым сайце imsha.by (імша бай). Тут вы знойдзеце актуальны расклад лацінскай рыма-каталіцкай Імшы і візантыйскай грэка-каталіцкай Літургіі.',
    },
    report: 'Калі вы знайшлі памылку, калі ласка, напішыце нам на '
  },
  header: {
    socialNetwork: 'Сачы за праектам'
  },
  aboutPage: {
    title: 'Аб праекце',
    greeting: 'Хвала Хрысту!',
    description: 'гэта каталіцкі валанцёрскі IT праект.',
  },
  link: {
    schedule: 'Расклад',
    about: 'Аб праекце',
    registerVolunteer: 'Стаць валанцерам',
  },
});

export default translationBE;
