import axios, { AxiosResponse } from 'axios';
import { guard, sample } from 'effector';

import {
  $aboutPage,
  $staticData,
  fetchAboutPageData,
  fetchAboutPageDataFx,
  fetchStaticData,
  fetchStaticDataFx,
} from './';
import {AboutPageStaticData, StrapiStaticData} from './types';

fetchStaticDataFx.use(async (lang) => {
  const {
    NEXT_PUBLIC_STRAPI_URL,
    NEXT_PUBLIC_STRAPI_TOKEN,
  } = process.env;

  const res: AxiosResponse = await axios.get(
    `${NEXT_PUBLIC_STRAPI_URL}/api/schedule-page?locale=${lang}&populate[0]=footer&populate[1]=theme&populate[2]=theme.logo&populate[3]=seo&populate[4]=banner&populate[5]=meta`,
    {
      headers: {
        'Authorization': `Bearer ${NEXT_PUBLIC_STRAPI_TOKEN}`
      }
    }
  );
  const strapiResponse: StrapiStaticData = {
    ...res.data,
  };
  return strapiResponse;
});

$staticData.on(
  fetchStaticDataFx.doneData, (_, state) => state
);


sample({
  clock: fetchStaticData,
  target: fetchStaticDataFx
});


fetchAboutPageDataFx.use(async (lang) => {
  const {
    NEXT_PUBLIC_STRAPI_URL,
    NEXT_PUBLIC_STRAPI_TOKEN,
  } = process.env;

  const res: AxiosResponse = await axios.get(
    `${NEXT_PUBLIC_STRAPI_URL}/api/about-page?locale=${lang}&populate[0]=points&populate[1]=points.Icon&populate[2]=photos_section_1&populate[3]=photos_section_2&populate[4]=photos_section_3&populate[5]=main_photo`,
    {
      headers: {
        'Authorization': `Bearer ${NEXT_PUBLIC_STRAPI_TOKEN}`
      }
    }
  );
  const strapiResponse: AboutPageStaticData = {
    ...res.data,
  };
  return strapiResponse;
});

$aboutPage.on(
  fetchAboutPageDataFx.doneData, (_, state) => state
);


sample({
  clock: fetchAboutPageData,
  target: fetchAboutPageDataFx
});
