import { LocaleDict } from 'locales';
const translationEN = () : LocaleDict => ({
  title: 'Holy Mass',
  description: 'Holy Eucharist in Minsk: schedule, time and place. Roman Catholic Mass and Greek Catholic Liturgy',
  filterName: 'Filters',
  radioTitle: 'Listen to the Holy Mass',
  radioSubTitle: 'Radio Maria in Belarus',
  toggleBtnTitle: (count: number) => (count === 1) ? 'Holy Mass at 1 parish' : `Holy Mass at ${count} parishes`,
  messLangLabel: 'Language',
  messOnline: 'Holy Mass online',
  messActual: ' Information is relevant',
  messActualInfo: 'The last update of the parish\'s Mass schedule was less than 7 days ago',
  messNotActualInfo: 'The last update of the Mass schedule of this parish was more than 7 days ago',
  messNotActualLastUpdate: 'Last update',
  why: 'Why?',
  messNotActual: 'Information requires confirmation',
  noOptionsText: 'No items found',
  filterPlaceholder: 'Search',
  filterLangLabel: 'Mass language',
  filterParishLabel: 'Parish',
  filterCityLabel: 'City',
  filterAttendance: {
    title: 'Show only online Masses',
    subtitle: 'video broadcasting',
  },
  filterRorate: {
    title: 'Show only rorate Masses',
  },
  footer: {
    about: 'About project',
    info: {
      leftColumn: 'Where and what time can you meet Jesus in the capital of Belarus and how to find an up-to-date schedule of services? Information about place and time of Holy Eucharist can be found at www.imsha.by ',
      rightColumn: 'Here you can find an up-to-date schedule of Latin Roman-Catholic service and Byzantian Greek-Catholic liturgy. ',
    },
    report: 'If you find any mistake, please, contact us via '
  },
  header: {
    socialNetwork: 'Follow the project'
  },
  aboutPage: {
    title: 'About project',
    greeting: 'Glory be to Jesus Christ!',
    description: 'is a Catholic volunteer IT project.',
  },
  link:{
    schedule: 'Schedule',
    about: 'About project',
    registerVolunteer: 'Become a volunteer',
  },
});

export default translationEN;
