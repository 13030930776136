import { LocaleDict } from 'locales';
const translationRU = (): LocaleDict => ({
  title: 'Святая Месса',
  description: 'Святая Евхаристия в городе Минск: расписание, время и место. Римско-Католическая Месса и Греко-Католическая Литургия',
  filterName: 'Фильтр Месс',
  radioTitle: 'Слушать Святую Мессу',
  radioSubTitle: 'трансляция Радио Мария в Беларуси',
  toggleBtnTitle: (count: number) => (count === 1) ? 'Святая Месса в 1 приходе' : `Святая Месса в ${count} приходах`,
  messLangLabel: 'Язык',
  messOnline: 'Святая Месса online',
  messActual: 'Информация актуальная',
  messActualInfo: 'Последнее обновление расписания Месс этого прихода было менее 7 дней назад',
  messNotActualInfo: 'Последнее обновление расписания Месс этого прихода было более чем 7 дней назад',
  messNotActual: ' Информация требует уточнений',
  messNotActualLastUpdate: 'Последнее обновление',
  why: 'Почему?',
  noOptionsText: 'не найдено',
  filterPlaceholder: 'Поиск',
  filterLangLabel: 'Язык Мессы',
  filterParishLabel: 'Приход',
  filterCityLabel: 'Населенный пункт',
  filterAttendance: {
    title: 'Отображать только online Мессы',
    subtitle: 'видео трансляция',
  },
  filterRorate: {
    title: 'Отображать только роратние Мессы',
  },
  footer: {
    about: 'О проекте',
    info: {
      leftColumn: 'Где и в котором часу можно встретиться с Христом в столице Беларуси? Как найти актуальное расписание богослужений в Минке? Где и во сколько будет католическая Месса в костеле или Месса онлайн? ',
      rightColumn: 'Информация про место и время совершения святой Евхаристии в Минске представлена на сайте imsha.by (имша бай). Здесь находится актуальное расписание латинской римо-католической Мессы и византийской греко-католической Литургии. ',
    },
    report: 'Если вы нашли ошибку, пожалуйста, напишите нам на '
  },
  header: {
    socialNetwork: 'Следи за проектом'
  },
  aboutPage: {
    title: 'О проекте',
    greeting: 'Слава Иисусу Христу!',
    description: 'это католический волонтерский IT проект.',
  },
  link: {
    schedule: 'Расписание',
    about: 'О проекте',
    registerVolunteer: 'Стать волонтером',
  },
});

export default translationRU;
