import { createEffect, createEvent, createStore } from 'effector';
import persist from 'effector-localstorage';

// import root from 'models/root';
import {
  QueryFilter,
  WeekSchedule,
} from './types';


export const fetchSchedule = createEvent<string>();
export const changeFilter = createEvent<QueryFilter>();
export const resetFilter = createEvent();

export const fetchWeekScheduleFx = createEffect<QueryFilter, WeekSchedule>();
export const resetFilterFx = createEffect();


export const $filter = createStore<QueryFilter>({
  massLang: null,
  parishId: null,
  city: {label: 'Мінск', id: '5bf05cf40a975a0f118ff14b'},
  online: false,
  lang: 'be',
  active: true,
  rorate: false,
});

persist({
  store: $filter,
  key: 'filterState',
});

export const $isLoading = createStore<boolean>(false);

export const $baseSchedule = createStore<WeekSchedule>({
  startWeekDate: new Date(),
  schedule: [{
    date: new Date(),
    massHours: [],
  }],
  nav: {
    selected: {
      city: {
        type: '',
        value: '',
        name: '',
      },
      online: {
        type: '',
        value: '',
        name: '',
      },
    },
    guided: {
      parish: [],
      city: [],
      lang: [],
      online: [],
      rorate: [],
    },
  }
});

export const $schedule = $baseSchedule.map(baseSchedule => {
  return baseSchedule.schedule;
});

export const $nav = $baseSchedule.map(baseSchedule => {
  return baseSchedule.nav;
});

export const $filterOptions = $nav.map(navElem => {
  const defaultValues = {
    city: [],
    lang: [],
    online: [],
    parish: [],
    rorate: [],
  };
  return {
    ...defaultValues,
    ...navElem.guided,
  };
});
