import { useStore } from 'effector-react';
import { $language } from 'models/staticData';

import translationBE from './be/translation';
import translationEN from './en/translation';
import translationPL from './pl/translation';
import translationRU from './ru/translation';

export const LANGUAGES  = ['be', 'ru', 'en', 'pl'];

export type Languages = 'be' | 'ru' | 'pl' | 'en';
export const defaultLang: Languages = 'be';

export type LocaleDict = ReturnType<typeof translationBE>;

interface LocaleResources {
  en: LocaleDict,
  be: LocaleDict,
  pl: LocaleDict,
  ru: LocaleDict,
}
const resources: LocaleResources = {
  en: translationEN(),
  be: translationBE(),
  ru: translationRU(),
  pl: translationPL(),
};

export const useLang = () => {
  const locale = useStore($language);
  return (resources[(locale as Languages) || defaultLang] as LocaleDict);
};