import { LocaleDict } from 'locales';
const translationPL = () : LocaleDict => ({
  title: 'Msza Święta',
  description: 'Święta Eucharystia w mieście Mińsk: rozkład, czas i miejsce. Msza Rzymskokatolicka i Liturgia Greckokatolicka',
  filterName: 'Filtry',
  radioTitle: 'Transmisja Mszy Świętej',
  radioSubTitle: 'Radio Maria na Białorusi',
  toggleBtnTitle: (count: number) => (count === 1) ? 'Msza Święta w 1 parafii' : `Msza Święta w ${count} parafiach`,
  messLangLabel: 'Język',
  messOnline: 'Msza online',
  messActual: ' Informacja aktualna ',
  messActualInfo: 'Ostatnia aktualizacja harmonogramu Mszy tej parafii była niespełna 7 dni temu',
  messNotActualInfo: 'Ostatnia aktualizacja harmonogramu Mszy tej parafii miała miejsce ponad 7 dni temu',
  messNotActual: ' Informacja wymaga wyjaśnienia ',
  messNotActualLastUpdate: 'Ostatnia aktualizacja',
  why: 'Dlaczego?',
  noOptionsText: 'nic nie znaleziono',
  filterPlaceholder: 'Poszukiwania',
  filterLangLabel: 'Język',
  filterParishLabel: 'Parafia',
  filterCityLabel: 'Miejscowość',
  filterAttendance: {
    title: 'Pokazać tylko Mszę online',
    subtitle: 'transmisja na żywo',
  },
  filterRorate: {
    title: 'Pokazać tylko roratnie Mszę',
  },
  footer: {
    about: 'O projekcie',
    info: {
      leftColumn: 'Gdzie w stolicy Białorusi można spotkać się z Chrystusem? Gdzie szukać aktualnego rozkładu Mszy Świętych, odprawianych w Mińsku? Na stronie www.imsha.by jest umieszczona informacja o miejscach i godzinach celebracji Eucharystii w Mińsku białoruskim. ',
      rightColumn: 'Znajdziesz tu aktualne rozkłady Mszy łacińskiej (obrządku rzymskokatolickiego) oraz Liturgii bizantyjskiej (obrządku greckokatolickiego). ',
    },
    report: 'Jeśli znajdziesz błąd, napisz do nas na '
  },
  header: {
    socialNetwork: 'Śledź nas na'
  },
  aboutPage: {
    title: 'O projekcie',
    greeting: 'Szczęść Boże!',
    description: 'to katolicki wolontariacki projekt IT.',
  },
  link: {
    schedule: 'Rozkład',
    about: 'O projekcie',
    registerVolunteer: 'Zostań wolontariuszem',
  },
});

export default translationPL;