import root from 'models/root';

import defaultValues from './defaultValues.json';
import {AboutPageStaticData, StrapiStaticData} from './types';


export const $staticData = root.createStore<StrapiStaticData>(defaultValues);
export const $aboutPage = root.createStore<AboutPageStaticData | null>(null);

export const $header = $staticData.map(staticData => staticData.data.attributes.header);
export const $banner = $staticData.map(staticData => staticData.data.attributes.banner);
export const $footer = $staticData.map(staticData => staticData.data.attributes.footer);
export const $seo = $staticData.map(staticData => staticData.data.attributes.seo);
export const $meta = $staticData.map(staticData => staticData.data.attributes.meta);
export const $localizations = $staticData.map(staticData => staticData.data.attributes.localizations);
export const $theme = $staticData.map(staticData => staticData.data.attributes.theme);
export const $logo = $theme.map(theme => theme.data.attributes.logo.data.attributes);

export const $language = $staticData.map(staticData => staticData.data.attributes.locale);

export const fetchStaticDataFx = root.createEffect<string, StrapiStaticData>();
export const fetchAboutPageDataFx = root.createEffect<string, AboutPageStaticData>();
export const getLanguageFx = root.createEffect();

export const fetchAboutPageData = root.createEvent<string>();
export const fetchStaticData = root.createEvent<string>();
