import 'moment/locale/be';
import 'moment/locale/ru';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import 'styles/main.scss';
import 'models/init';

import {fork,Scope, serialize} from 'effector';
import { Provider as EffectorProvider } from 'effector-react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import {ThemeProvider} from 'styles/Theme/ThemeProvider';



interface MyAppProps extends AppProps {
  pageProps: {
    store: object,
  }
};

let clientScope: Scope;

function MyApp({ Component, pageProps }: MyAppProps) {
  const scope = fork({
    values: {
      ...(clientScope && serialize(clientScope)),
      ...pageProps.store,
    }
  });
  if (typeof window !== 'undefined') clientScope = scope;

  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      {/* Global site tag (gtag.js) - Google Analytics */}
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=UA-129690230-1"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-129690230-1');
        `}
      </Script>
      <CookiesProvider>
        <EffectorProvider value={scope}>
          <ThemeProvider>
            <Component {...pageProps} />
          </ThemeProvider>
        </EffectorProvider>
      </CookiesProvider>
    </>
  );
}

export default MyApp;
